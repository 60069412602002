import React, { useState, useEffect } from "react";
import AmlIdentityService from "../services/AmlIdentityService";

const amlIdentityService = new AmlIdentityService();

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const PersonaBusTrial = () => {
  const [formData, setFormData] = useState({
    businessName: 'Man Steel',
    businessTaxIdentificationNumber: '234234234',
    businessPhysicalAddress: {
      street1: '1 Main St',
      street2: '6',
      city: 'Portland',
      subdivision: 'OR',
      postalCode: '19832',
      countryCode: 'US',
    },
    businessRegisteredAddress: {
      street1: '45 Dawn St',
      street2: '8',
      city: 'Oswego',
      subdivision: 'NY',
      postalCode: '10366',
      countryCode: 'US',
    },
    crdIndividual: '123456',
    crdFirm: '9876',
    refId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const newState = { ...prevState };
      if (name.startsWith('businessPhysicalAddress.')) {
        const key = name.replace('businessPhysicalAddress.', '');
        newState.businessPhysicalAddress[key] = (value === null) ? '' : value;
      } else if (name.startsWith('businessRegisteredAddress.')) {
        const key = name.replace('businessRegisteredAddress.', '');
        newState.businessRegisteredAddress[key] = (value === null) ? '' : value;
      } else {
        newState[name] = (value === null) ? '' : value;
      }
      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submit action
    console.log(`Business Name: ${formData.businessName}, Tax ID: ${formData.businessTaxIdentificationNumber}`);
    const result = await amlIdentityService.sendBusiness(formData);
    console.log('refRes', result);
    const { "reference-id": resReferenceId } = result;
    console.log('referenceId', resReferenceId);

    for (let i = 0; i < 6; i++) {
      console.log('i', i);

      const info = await amlIdentityService.getInfo(resReferenceId);
      console.log('info', info);
      if (info.data === 'created') {
        console.log('Transaction has been created.');
      } else if (info.data === 'approved') {
        console.log('Transaction has been approved: you can proceed with the user.');
        break;
      } else if (info.data === 'declined') {
        console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
        break;
      } else if (info.data === 'needs_review') {
        console.log('Transaction is awaiting manual review (there was a report hit)');
        break;
      } else if (info.data === 'errored') {
        console.log('Transaction has errored.');
        break;
      }

      await delay(5000);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="businessName">Business Name:</label>
        <input
          type="text"
          id="businessName"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessTaxIdentificationNumber">EIN:</label>
        <input
          type="text"
          id="businessTaxIdentificationNumber"
          name="businessTaxIdentificationNumber"
          value={formData.businessTaxIdentificationNumber}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Physical Address:</label>
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.street1">Street 1:</label>
        <input
          type="text"
          id="businessPhysicalAddress.street1"
          name="businessPhysicalAddress.street1"
          value={formData.businessPhysicalAddress.street1}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.street2">Street 2:</label>
        <input
          type="text"
          id="businessPhysicalAddress.street2"
          name="businessPhysicalAddress.street2"
          value={formData.businessPhysicalAddress.street2}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.city">City:</label>
        <input
          type="text"
          id="businessPhysicalAddress.city"
          name="businessPhysicalAddress.city"
          value={formData.businessPhysicalAddress.city}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.subdivision">State:</label>
        <input
          type="text"
          id="businessPhysicalAddress.subdivision"
          name="businessPhysicalAddress.subdivision"
          value={formData.businessPhysicalAddress.subdivision}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.postalCode">Postal Code:</label>
        <input
          type="text"
          id="businessPhysicalAddress.postalCode"
          name="businessPhysicalAddress.postalCode"
          value={formData.businessPhysicalAddress.postalCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessPhysicalAddress.countryCode">Country Code:</label>
        <input
          type="text"
          id="businessPhysicalAddress.countryCode"
          name="businessPhysicalAddress.countryCode"
          value={formData.businessPhysicalAddress.countryCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Registered Address:</label>
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.street1">Street 1:</label>
        <input
          type="text"
          id="businessRegisteredAddress.street1"
          name="businessRegisteredAddress.street1"
          value={formData.businessRegisteredAddress.street1}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.street2">Street 2:</label>
        <input
          type="text"
          id="businessRegisteredAddress.street2"
          name="businessRegisteredAddress.street2"
          value={formData.businessRegisteredAddress.street2}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.city">City:</label>
        <input
          type="text"
          id="businessRegisteredAddress.city"
          name="businessRegisteredAddress.city"
          value={formData.businessRegisteredAddress.city}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.subdivision">State:</label>
        <input
          type="text"
          id="businessRegisteredAddress.subdivision"
          name="businessRegisteredAddress.subdivision"
          value={formData.businessRegisteredAddress.subdivision}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.postalCode">Postal Code:</label>
        <input
          type="text"
          id="businessRegisteredAddress.postalCode"
          name="businessRegisteredAddress.postalCode"
          value={formData.businessRegisteredAddress.postalCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="businessRegisteredAddress.countryCode">Country Code:</label>
        <input
          type="text"
          id="businessRegisteredAddress.countryCode"
          name="businessRegisteredAddress.countryCode"
          value={formData.businessRegisteredAddress.countryCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="crdIndividual">Individual CRD:</label>
        <input
          type="text"
          id="crdIndividual"
          name="crdIndividual"
          value={formData.crdIndividual}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="crdFirm">Firm CRD:</label>
        <input
          type="text"
          id="crdFirm"
          name="crdFirm"
          value={formData.crdFirm}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="refId">Reference ID:</label>
        <input
          type="text"
          id="refId"
          name="refId"
          value={formData.refId}
          onChange={handleChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default PersonaBusTrial;
